import axios from 'axios';
import moment from 'moment';
import { isArrayLength } from './genericHelpers';
import { apiBaseUrl, deleteFileAWS } from './api';
export async function uploadFiles({
  newFiles,
  form,
  fieldName,
  onUploadProgress,
  setIsFileUploading,
}) {
  const currentFiles = form.getState().values[fieldName] || [];

  setIsFileUploading(true);

  if (!isArrayLength(newFiles)) {
    setIsFileUploading(false);

    return;
  }

  const filteredFiles = currentFiles.filter(item => item?.fileLink) || [];
  const updatedFiles = [...filteredFiles];

  try {
    for (const file of newFiles) {
      const formData = new FormData();
      formData.append('file', file);

      const { data = {} } = await axios.post(`${apiBaseUrl()}/api/upload-file-aws`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
          if (onUploadProgress) {
            onUploadProgress(progressEvent, file.name);
          }
        },
      });

      const { data: awsResponse = {} } = data;
      const { objectURL, key } = awsResponse;
      const currentDate = moment().format('MM-DD-YYYY hh:mm:ss');

      updatedFiles.push({
        fileLink: objectURL,
        date: currentDate,
        name: file.name,
        size: file.size,
        key,
      });
    }

    form.change(fieldName, updatedFiles);
  } catch (error) {
  } finally {
    setIsFileUploading(false);
  }
}
export async function handleDeleteFile({ file, form, fieldName }) {
  if (!file || !form || !fieldName) {
    console.error('Missing required parameters: file, form, or fieldName');
    return null;
  }

  try {
    const currentFiles = form.getState().values?.[fieldName] || [];
    console.log(currentFiles, 'Current files in the form state');

    // Filter out the file to be deleted
    const filteredFiles = currentFiles.filter(
      item => !(item?.key === file?.key && item?.name === file?.name)
    );

    // Delete the file from AWS or other storage
    const response = await deleteFileAWS({ key: file.key });

    if (response?.statusCode === 200) {
      form.change(fieldName, filteredFiles); // Update the form state
      return { success: true, message: 'File deleted successfully' };
    } else {
      console.error('Failed to delete file from AWS', response);
      throw new Error(`Failed to delete file: ${response?.message || 'Unknown error'}`);
    }
  } catch (error) {
    console.error('Error while deleting a file:', error);
    return { success: false, message: `Error while deleting a file: ${error.message}` };
  }
}
