export const UserType = Object.freeze({
  COMPANY_USER: 'company-user',
  DESIGNER_USER: 'designer-user',
});

export const ListingType = {
  COMPANY: 'company',
  DESIGNER: 'designer',
};
export const Budget = {
  HOURLY: 'hourly',
  FIXED: 'fixed',
};

export const LISTING_CONFIG_KEYS = {
  CATEGORY: 'category',
  EXPERIENCE_LEVEL: 'experienceLevel',
  PROJECT_SIZE_LISTING: 'marketType',
  CATEGORY_LEVEL: 'category',
};

export const StatusCode = {
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  TOO_MANY_REQUESTS: 429,
};
export const MilestoneState = Object.freeze({
  CREATED: 'Created',
  COMPLETED: 'Completed',
  FUNDED: 'Funded',
  REQUEST_APPROVAL: 'Request Approval',
  SUBMIT_MILESTONE: 'Submit Milestone',
  FINISH_MILESTONE: 'Completed & Payment Approved',
  APPROVED: 'Approved',
  REQUEST_CHANGES: 'Request Changes',
});

export const TimesheetPanelTabs = Object.freeze({
  OVERVIEW: 'Overview',
  TIMESHEET: 'Timesheet',
  MESSAGES: 'Messages',
  CONTRACT_DETAILS: 'Contract Details',
});
export const CalendarViews = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
  YEAR: 'year',
};

export const FileExtension = Object.freeze({
  JPEG: 'jpeg',
  JPG: 'jpg',
  PNG: 'png',
});

export const UserFieldConfigKey = Object.freeze({
  CERTACCRED: 'certaccred',
  MSS: 'mss',
  MANUFACTURERS: 'manufacturers',
});

export const DEFAULT_HOURLY = 'default-hourly';

export const TransactionRole = Object.freeze({
  PROVIDER: 'provider',
  CUSTOMER: 'customer',
  OPERATOR: 'operator',
});

export const ListingUnderContract = Object.freeze({
  UNDER_CONTRACT: true,
  NOT_UNDER_CONTRACT: false,
});
export const SendgridEmailTemplate = Object.freeze({
  CONTACT_US: 'CONTACT_US_UD',
});
