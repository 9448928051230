import moment from 'moment';
import { useCallback, useRef, useState } from 'react';

export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const bytesToMB = bytes => {
  if (!bytes || isNaN(bytes)) {
    return 'N/A'; // Return an empty string or a placeholder if bytes is invalid
  }
  return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
};

/**
 * Returns a capitalized string.
 * @param {string} str - The string to capitalize.
 * @returns {string|null} The capitalized string, or null if input is invalid.
 */
export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};
export const calculateTimeDifference = (startTime, endTime) => {
  const start = moment(startTime, 'h:mm A');
  const end = moment(endTime, 'h:mm A');

  // Check if end time is before start time, and if so, add 24 hours to end time
  if (end.isBefore(start)) {
    end.add(1, 'day');
  }

  const duration = moment.duration(end.diff(start));
  const hours = duration.asHours();
  return hours;
};
export const formatTimeDifference = hours => {
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);
  return `${h}:${m}`;
};

// Helper function to convert total hours (e.g., "2:30 hrs") to a decimal number
export const convertHoursToDecimal = totalHours => {
  if (!totalHours) return 0;
  const [hours, minutes] = totalHours.split(':').map(Number);
  return hours + minutes / 60;
};
export const normalizedStatus = status => status?.trim().toLowerCase();

export const openFileInNewTab = file => {
  if (!file?.fileLink) return null;
  if (!file && typeof window !== 'undefined') return null;
  window.open(file.fileLink, '_blank');
};
export const extractFileExtension = file => {
  // Check if file is valid and has a 'name' property
  if (!file || !file.name) {
    return null; // Return null if file or file.name is not defined
  }

  // Extract and return the file extension
  return file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
};

export const getLabelsByOptions = (educationAndAffiliations, selectedOptions) => {
  if (!(isArrayLength(selectedOptions) && educationAndAffiliations?.key)) {
    return null;
  }
  return selectedOptions
    ?.map(selectedOption => {
      const match = educationAndAffiliations.enumOptions.find(
        enumOption => enumOption.option === selectedOption
      );
      return match ? match.label : null; // Returns the label or null if no match is found
    })
    .filter(label => label !== null); // Filters out null values
};

export const truncateText = (text, maxLength = 15) => {
  if (!text) return '';
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

export const excludeValidation = name => {
  return ['additionalmanufacturers', 'certaccred', 'designstyle'].includes(name);
};
