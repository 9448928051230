import pick from 'lodash/pick';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities, merge } from '../../ducks/marketplaceData.duck';
import { transactionLineItems, updateMetadataThroughIsdk } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedEntities, denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import {
  hasPermissionToInitiateTransactions,
  hasPermissionToViewData,
  isUserAuthorized,
} from '../../util/userHelpers';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { getProcess, isBookingProcessAlias } from '../../transactions/transaction';
import {
  fetchCurrentUser,
  fetchCurrentUserHasOrdersSuccess,
  markTransactionAsReadHelper,
} from '../../ducks/user.duck';
import { resultIds } from '../SearchPage/SearchPage.duck';
import { ListingType } from '../../util/enums';
import { transitions } from '../../transactions/transactionProcessHireDesinger';
import {
  HIRE_DESIGNER_PROCESS,
  TRANSITION_ACCEPT_CONTRACT,
  TRANSITION_ACCEPT_CONTRACT_AFTER_HIRING,
} from '../../util/types';

const { UUID } = sdkTypes;

const QUERY_LISTINGS_PER_PAGE = 4;
// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';
export const ADD_MARKETPLACE_ENTITIES = 'app/ListingPage/ADD_MARKETPLACE_ENTITIES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TRANSACTIONS_REQUEST = 'app/ListingPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/ListingPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/ListingPage/FETCH_TRANSACTIONS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const HIRE_DESIGNER_REQUEST = 'app/ListingPage/HIRE_DESIGNER_REQUEST';
export const HIRE_DESIGNER_SUCCESS = 'app/ListingPage/HIRE_DESIGNER_SUCCESS';
export const HIRE_DESIGNER_ERROR = 'app/ListingPage/HIRE_DESIGNER_ERROR';

export const QUERY_LISTING_REQUEST = 'app/ListingPage/QUERY_LISTING_REQUEST';
export const QUERY_LISTING_SUCCESS = 'app/ListingPage/QUERY_LISTING_SUCCESS';
export const QUERY_LISTING_ERROR = 'app/ListingPage/QUERY_LISTING_ERROR';

export const QUERY_DESIGNER_LISTING_REQUEST = 'app/ListingPage/QUERY_DESIGNER_LISTING_REQUEST';
export const QUERY_DESIGNER_LISTING_SUCCESS = 'app/ListingPage/QUERY_DESIGNER_LISTING_SUCCESS';
export const QUERY_DESIGNER_LISTING_ERROR = 'app/ListingPage/QUERY_DESIGNER_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquiryModalOpenForListingId: null,
  queryListingInProgress: false,
  queryListingsError: null,
  relatedJobsListingsId: [],
  entities: {},
  queryDesignerListingInProgress: false,
  designerListing: null,
  queryDesignerListingError: null,
  hireDesignerInProgress: false,
  hireDesignerError: null,
  acceptContractInProgress: false,
  acceptContractError: null,
  fetchTransactionsInProgress: false,
  fetchTransactionsError: null,
  acceptedContractTransaction: {},
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false, inquiryModalOpenForListingId: null };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };
    case HIRE_DESIGNER_REQUEST:
      return { ...state, hireDesignerInProgress: true, hireDesignerError: null };
    case HIRE_DESIGNER_SUCCESS:
      return { ...state, hireDesignerInProgress: false };
    case HIRE_DESIGNER_ERROR:
      return { ...state, hireDesignerInProgress: false, hireDesignerError: payload };
    case QUERY_LISTING_REQUEST:
      return {
        ...state,
        queryListingInProgress: true,
        queryListingsError: null,
        relatedJobsListingsId: [],
      };
    case QUERY_LISTING_SUCCESS:
      return {
        ...state,
        queryListingInProgress: false,
        relatedJobsListingsId: resultIds(payload.data),
      };
    case QUERY_LISTING_ERROR:
      return {
        ...state,
        queryListingInProgress: false,
        queryListingsError: payload,
        relatedJobsListingsId: [],
      };

    case QUERY_DESIGNER_LISTING_REQUEST:
      return {
        ...state,
        queryDesignerListingInProgress: true,
        designerListing: null,
        queryDesignerListingError: null,
      };
    case QUERY_DESIGNER_LISTING_SUCCESS:
      return {
        ...state,
        queryDesignerListingInProgress: false,
        designerListing: payload,
        queryDesignerListingError: null,
      };
    case QUERY_DESIGNER_LISTING_ERROR:
      return {
        ...state,
        queryDesignerListingInProgress: false,
        designerListing: null,
        queryDesignerListingError: payload,
      };
    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchTransactionsInProgress: true,
        designerListing: null,
        fetchTransactionsError: null,
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        fetchTransactionsInProgress: false,
        designerListing: payload,
        fetchTransactionsError: null,
        acceptedContractTransaction: payload,
      };
    case FETCH_TRANSACTIONS_ERROR:
      return {
        ...state,
        fetchTransactionsInProgress: false,
        designerListing: null,
        fetchTransactionsError: payload,
      };
    case ADD_MARKETPLACE_ENTITIES:
      return merge(state, payload);
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action Selectors ================ //
export const queryListingSelectors = state => {
  const { relatedJobsListingsId, queryListingInProgress, queryListingsError } = state.ListingPage;
  return { relatedJobsListingsId, queryListingInProgress, queryListingsError };
};
export const designerListingSelector = state => {
  const {
    queryDesignerListingInProgress,
    designerListing,
    queryDesignerListingError,
  } = state.ListingPage;
  return { queryDesignerListingInProgress, designerListing, queryDesignerListingError };
};
export const getListingsById = (state, listingIds) => {
  const { entities } = state.ListingPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(entities, resources, throwIfNotFound);
};
export const hireDesignerSelector = state => {
  const { hireDesignerInProgress, hireDesignerError } = state.ListingPage;
  return { hireDesignerInProgress, hireDesignerError };
};
export const acceptedContractTransactionSelector = state => {
  const { acceptedContractTransaction, fetchTransactionsInProgress } = state.ListingPage;
  return { acceptedContractTransaction, fetchTransactionsInProgress };
};
// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

export const hireDesingerRequest = () => ({ type: HIRE_DESIGNER_REQUEST });
export const hireDesingerSuccess = () => ({ type: HIRE_DESIGNER_SUCCESS });
export const hireDesignerError = e => ({ type: HIRE_DESIGNER_ERROR, error: true, payload: e });

export const queryListingRequest = () => ({ type: QUERY_LISTING_REQUEST });
export const queryListingSuccess = response => ({
  type: QUERY_LISTING_SUCCESS,
  payload: { data: response.data },
});
export const queryListingError = e => ({ type: QUERY_LISTING_ERROR, error: true, payload: e });

export const queryDesignerListingRequest = () => ({ type: QUERY_DESIGNER_LISTING_REQUEST });
export const queryDesignerListingSuccess = response => ({
  type: QUERY_DESIGNER_LISTING_SUCCESS,
  payload: response,
});
export const queryDesignerListingError = e => ({
  type: QUERY_DESIGNER_LISTING_ERROR,
  error: true,
  payload: e,
});
export const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });
export const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: response,
});
export const fetchTransactionError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

export const addListingEntities = (sdkResponse, sanitizeConfig) => ({
  type: ADD_MARKETPLACE_ENTITIES,
  payload: { sdkResponse, sanitizeConfig },
});

// ================ Thunks ================ //

export const showListing = (listingId, config, isOwn = false) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  // Current user entity is fetched in a bit lazy fashion, since it's not tied to returned Promise chain.
  const fetchCurrentUserOptions = {
    updateHasListings: false,
    updateNotifications: false,
  };
  dispatch(fetchCurrentUser(fetchCurrentUserOptions));

  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock', 'createdAt'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(data, sanitizeConfig));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendInquiry = (listing, message) => async (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());

  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }
  const { currentUser } = getState()?.user || {};
  const listingId = listing?.id;
  const currentAuthorId = listing?.author?.id?.uuid;
  const currentUserId = currentUser?.id?.uuid;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;
  if (!transitions || !transitions.INQUIRE_WITHOUT_PAYMENT) {
    const error = new Error('Transition INQUIRE_WITHOUT_PAYMENT not found in process');
    log.error(error, 'transition-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const bodyParams = {
    transition: transitions.INQUIRE_WITHOUT_PAYMENT, //Need to refactor
    processAlias,
    params: { listingId },
  };

  try {
    const response = await sdk.transactions.initiate(bodyParams);
    const transactionId = response.data.data.id;

    await sdk.messages.send({ transactionId, content: message });

    await dispatch(
      markTransactionAsReadHelper({
        transaction: response.data.data,
        isRead: false,
        currentUserId,
        currentAuthorId,
        isInitial: true,
      })
    );
    dispatch(sendInquirySuccess());
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return transactionId;
  } catch (error) {
    dispatch(sendInquiryError(storableError(error)));
    throw error;
  }
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';
    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};
export const queryListings = config => async (dispatch, getState, sdk) => {
  dispatch(queryListingRequest());
  try {
    const queryParams = {
      pub_type: ListingType.COMPANY,
      perPage: QUERY_LISTINGS_PER_PAGE,
      include: ['author', 'author.profileImage', 'images', 'currentStock', 'createdAt'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'createdAt'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.scaled-small', 'variants.scaled-medium'],
      'limit.images': 1,
    };
    const response = await sdk.listings.query(queryParams);
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };
    dispatch(addListingEntities(response, sanitizeConfig));
    dispatch(queryListingSuccess(response));
  } catch (error) {
    dispatch(queryListingError(storableError(error)));
  }
};
export const queryDesignerListings = authorId => async (dispatch, getState, sdk) => {
  dispatch(queryDesignerListingRequest());
  try {
    const queryParams = {
      authorId,
      pub_type: ListingType.DESIGNER,
      perPage: QUERY_LISTINGS_PER_PAGE,
      include: ['author', 'author.profileImage', 'images', 'currentStock', 'createdAt'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'createdAt'],
      'fields.image': ['variants.scaled-small', 'variants.scaled-medium'],
      'limit.images': 1,
    };
    const { data: { data = [] } = {} } = await sdk.listings.query(queryParams);
    data && dispatch(queryDesignerListingSuccess(data[0]));
  } catch (error) {
    dispatch(queryDesignerListingError(storableError(error)));
  }
};
export const initiateHireDesigner = params => async (dispatch, getState, sdk) => {
  const { currentListing, currentUserId } = params || {};
  const listingId = currentListing?.id;
  const currentAuthorId = currentListing?.author?.id?.uuid;
  dispatch(hireDesingerRequest());
  try {
    const bodyParams = {
      transition: transitions.HIRE_DESINGER, //Need to refactor
      processAlias: HIRE_DESIGNER_PROCESS,
      params: {
        listingId,
      },
    };
    const { data: { data: transaction = [] } = {} } = await sdk.transactions.initiate(bodyParams);

    await dispatch(
      markTransactionAsReadHelper({
        transaction,
        isRead: false,
        currentUserId,
        currentAuthorId,
        isInitial: true,
      })
    );

    dispatch(hireDesingerSuccess());
    return transaction;
  } catch (error) {
    dispatch(hireDesignerError(storableError(error)));
  }
};
export const fetchTransactions = listingId => async (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  try {
    // Query the transactions related to the specified listingId
    const response = await sdk.transactions.query({ listingId });
    const transactions = response?.data?.data || [];

    // Filter the transactions that have accepted contracts
    const acceptedContractTransactions = transactions.find(transaction =>
      transaction?.attributes?.transitions?.some(transition =>
        [TRANSITION_ACCEPT_CONTRACT, TRANSITION_ACCEPT_CONTRACT_AFTER_HIRING].includes(
          transition?.transition
        )
      )
    );

    dispatch(fetchTransactionSuccess(acceptedContractTransactions));
    return transactions;
  } catch (error) {
    dispatch(fetchTransactionError(storableError(error)));
  }
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const listingId = new UUID(params.id);
  const state = getState();
  const currentUser = state.user?.currentUser;
  const inquiryModalOpenForListingId =
    isUserAuthorized(currentUser) && hasPermissionToInitiateTransactions(currentUser)
      ? state.ListingPage.inquiryModalOpenForListingId
      : null;

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null, inquiryModalOpenForListingId }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true));
  }
  dispatch(queryListings(config));

  // In private marketplace mode, this page won't fetch data if the user is unauthorized
  const isAuthorized = currentUser && isUserAuthorized(currentUser);
  const isPrivateMarketplace = config.accessControl.marketplace.private === true;
  const canFetchData = !isPrivateMarketplace || (isPrivateMarketplace && isAuthorized);
  if (!canFetchData) {
    return Promise.resolve();
  }

  const hasNoViewingRights = currentUser && !hasPermissionToViewData(currentUser);
  const promises = hasNoViewingRights
    ? // If user has no viewing rights, only allow fetching their own listing without reviews
      [dispatch(showListing(listingId, config, true))]
    : // For users with viewing rights, fetch the listing and the associated reviews
      [dispatch(showListing(listingId, config)), dispatch(fetchReviews(listingId))];

  return Promise.all(promises).then(response => {
    const listingResponse = response[0];
    const listing = listingResponse?.data?.data;
    const authorId = listing?.relationships?.author?.data?.id;
    if (authorId) {
      dispatch(queryDesignerListings(authorId));
      dispatch(fetchTransactions(listingId));
    }
    const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
    if (isBookingProcessAlias(transactionProcessAlias) && !hasNoViewingRights) {
      // Fetch timeSlots if the user has viewing rights.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      fetchMonthlyTimeSlots(dispatch, listing);
    }
    return response;
  });
};
