import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import {
  approveMilestoneFund,
  createListingUsingIsdk,
  fundMilestone,
  privilegedTransition,
  transactionLineItems,
  transitionTransaction,
  updateMetadataThroughIsdk,
} from '../../util/api';
import * as log from '../../util/log';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import {
  resolveLatestProcessName,
  getProcess,
  isBookingProcess,
} from '../../transactions/transaction';

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUserNotifications, markTransactionAsReadHelper } from '../../ducks/user.duck';
import { stripeCustomer } from '../CheckoutPage/CheckoutPage.duck';
import { transitions } from '../../transactions/transactionProcessHourly';
import {
  DEFAULT_HOURLY_PROCESS,
  DEFAULT_INQUIRY_PROCESS,
  TRANSITION_ACCEPT_CONTRACT,
  TRANSITION_ACCEPT_CONTRACT_AFTER_HIRING,
  TRANSITION_END_CONTRACT,
} from '../../util/types';
import {
  getContractDetails,
  getProcessName,
  getTransactionReadStatus,
} from '../../util/transactionDataExtractor';
import { Budget, StatusCode } from '../../util/enums';
import moment from 'moment';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';
export const ACCEPT_CONTRACT_REQUEST = 'app/ListingPage/ACCEPT_CONTRACT_REQUEST';
export const ACCEPT_CONTRACT_SUCCESS = 'app/ListingPage/ACCEPT_CONTRACT_SUCCESS';
export const ACCEPT_CONTRACT_ERROR = 'app/ListingPage/ACCEPT_CONTRACT_ERROR';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_OWN_LISTINGS_REQUEST = 'app/TransactionPage/FETCH_OWN_LISTINGS_REQUEST';
export const FETCH_OWN_LISTINGS_SUCCESS = 'app/TransactionPage/FETCH_OWN_LISTINGS_SUCCESS';
export const FETCH_OWN_LISTINGS_ERROR = 'app/TransactionPage/FETCH_OWN_LISTINGS_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const TRANSITION_REQUEST = 'app/TransactionPage/MARK_RECEIVED_REQUEST';
export const TRANSITION_SUCCESS = 'app/TransactionPage/TRANSITION_SUCCESS';
export const TRANSITION_ERROR = 'app/TransactionPage/TRANSITION_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const SEND_ATTACHMENTS_REQUEST = 'app/TransactionPage/SEND_ATTACHMENTS_REQUEST';
export const SEND_ATTACHMENTS_SUCCESS = 'app/TransactionPage/SEND_ATTACHMENTS_SUCCESS';
export const SEND_ATTACHMENTS_ERROR = 'app/TransactionPage/SEND_ATTACHMENTS_ERROR';

export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/TransactionPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/TransactionPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/TransactionPage/FETCH_LINE_ITEMS_ERROR';

export const MILESTONE_UPDATE_REQUEST = 'app/TransactionPage/MILESTONE_UPDATE_REQUEST';
export const MILESTONE_UPDATE_SUCCESS = 'app/TransactionPage/MILESTONE_UPDATE_SUCCESS';
export const MILESTONE_UPDATE_ERROR = 'app/TransactionPage/MILESTONE_UPDATE_ERROR';

export const FUND_MILESTONE_REQUEST = 'app/TransactionPage/FUND_MILESTONE_REQUEST';
export const FUND_MILESTONE_SUCCESS = 'app/TransactionPage/FUND_MILESTONE_SUCCESS';
export const FUND_MILESTONE_ERROR = 'app/TransactionPage/FUND_MILESTONE_ERROR';

export const MILESTONE_SUBMIT_REQUEST = 'app/TransactionPage/MILESTONE_SUBMIT_REQUEST';
export const MILESTONE_SUBMIT_SUCCESS = 'app/TransactionPage/MILESTONE_SUBMIT_SUCCESS';
export const MILESTONE_SUBMIT_ERROR = 'app/TransactionPage/MILESTONE_SUBMIT_ERROR';

export const APPROVE_MILESTONE_FUND_REQUEST = 'app/TransactionPage/APPROVE_MILESTONE_FUND_REQUEST';
export const APPROVE_MILESTONE_FUND_SUCCESS = 'app/TransactionPage/APPROVE_MILESTONE_FUND_SUCCESS';
export const APPROVE_MILESTONE_FUND_ERROR = 'app/TransactionPage/APPROVE_MILESTONE_FUND_ERROR';

export const UPDATE_TRANSACTION_METADATA_REQUESET =
  'app/TransactionPage/UPDATE_TRANSACTION_METADATA_REQUESET';
export const UPDATE_TRANSACTION_METADATA_SUCCESS =
  'app/TransactionPage/UPDATE_TRANSACTION_METADATA_SUCCESS';
export const UPDATE_TRANSACTION_METADATA_ERROR =
  'app/TransactionPage/UPDATE_TRANSACTION_METADATA_ERROR';

export const FETCH_JOB_LISTING_REQUEST = 'app/TransactionPage/FETCH_JOB_LISTING_REQUEST';
export const FETCH_JOB_LISTING_SUCCESS = 'app/TransactionPage/FETCH_JOB_LISTING_SUCCESS';
export const FETCH_JOB_LISTING_ERROR = 'app/TransactionPage/FETCH_JOB_LISTING_ERROR';

export const UPDATE_CONTRACT_LISTING_REQUEST =
  'app/TransactionPage/UPDATE_CONTRACT_LISTING_REQUEST';
export const UPDATE_CONTRACT_LISTING_SUCCESS =
  'app/TransactionPage/UPDATE_CONTRACT_LISTING_SUCCESS';
export const UPDATE_CONTRACT_LISTING_ERROR = 'app/TransactionPage/UPDATE_CONTRACT_LISTING_ERROR';

export const CREATE_CONTRACT_LISTING_REQUEST =
  'app/TransactionPage/CREATE_CONTRACT_LISTING_REQUEST';
export const CREATE_CONTRACT_LISTING_SUCCESS =
  'app/TransactionPage/CREATE_CONTRACT_LISTING_SUCCESS';
export const CREATE_CONTRACT_LISTING_ERROR = 'app/TransactionPage/CREATE_CONTRACT_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  transitionInProgress: null,
  transitionError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  updateMilestoneInProgress: false,
  updateMilestoneError: null,
  fundMilestoneInProgress: false,
  fundMilestoneError: null,
  milestoneSubmitInProgress: false,
  milestoneSubmitError: null,
  approveMilestoneFundInProgress: false,
  approveMilestoneFundError: null,
  updateMetadataInProgress: false,
  updateMetadataError: null,
  sendAttachmentsInProgress: false,
  sendAttachmentsError: null,
  acceptContractInProgress: false,
  acceptContractError: null,
  ownListings: [],
  ownListingsError: null,
  ownListingsInProgress: false,
  fetchJobListingInProgress: false,
  fetchJobListingError: null,
  jobListing: null,
  updateContractListingInProgress: false,
  updateContractListingError: null,
  createContractListingInProgress: false,
  createContractListingError: null,
  contractListing: null,
  markTransactionInProgress: false,
  markTransactionError: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function transactionPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };
    case ACCEPT_CONTRACT_REQUEST:
      return { ...state, acceptContractInProgress: true, acceptContractError: null };
    case ACCEPT_CONTRACT_SUCCESS:
      return { ...state, acceptContractInProgress: false, acceptContractError: null };
    case ACCEPT_CONTRACT_ERROR:
      return { ...state, acceptContractInProgress: false, acceptContractError: payload };
    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case TRANSITION_REQUEST:
      return {
        ...state,
        transitionInProgress: payload,
        transitionError: null,
      };
    case TRANSITION_SUCCESS:
      return { ...state, transitionInProgress: null };
    case TRANSITION_ERROR:
      return {
        ...state,
        transitionInProgress: null,
        transitionError: payload,
      };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };
    case SEND_ATTACHMENTS_REQUEST:
      return {
        ...state,
        sendAttachmentsInProgress: true,
        sendAttachmentsError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_ATTACHMENTS_SUCCESS:
      return { ...state, sendAttachmentsInProgress: false, sendAttachmentsError: null };
    case SEND_ATTACHMENTS_ERROR:
      return { ...state, sendAttachmentsInProgress: false, sendAttachmentsError: payload };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return { ...state, sendReviewInProgress: false, sendReviewError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };
    case FETCH_OWN_LISTINGS_REQUEST:
      return { ...state, ownListingsInProgress: true, ownListingsError: null };
    case FETCH_OWN_LISTINGS_SUCCESS:
      return { ...state, ownListingsInProgress: false, ownListings: payload };
    case FETCH_OWN_LISTINGS_ERROR:
      return { ...state, ownListingsInProgress: false, ownListingsError: payload };

    case MILESTONE_UPDATE_REQUEST:
      return { ...state, updateMilestoneInProgress: true, updateMilestoneError: null };
    case MILESTONE_UPDATE_SUCCESS:
      return { ...state, updateMilestoneInProgress: false, updateMilestoneError: null };
    case MILESTONE_UPDATE_ERROR:
      return { ...state, updateMilestoneInProgress: false, updateMilestoneError: payload };

    case FUND_MILESTONE_REQUEST:
      return { ...state, fundMilestoneInProgress: true, fundMilestoneError: null };
    case FUND_MILESTONE_SUCCESS:
      return { ...state, fundMilestoneInProgress: false, fundMilestoneError: null };
    case FUND_MILESTONE_ERROR:
      return { ...state, fundMilestoneInProgress: false, fundMilestoneError: payload };

    case MILESTONE_SUBMIT_REQUEST:
      return { ...state, milestoneSubmitInProgress: true, milestoneSubmitError: null };
    case MILESTONE_SUBMIT_SUCCESS:
      return { ...state, milestoneSubmitInProgress: false, milestoneSubmitError: null };
    case MILESTONE_SUBMIT_ERROR:
      return { ...state, milestoneSubmitInProgress: false, milestoneSubmitError: payload };

    case APPROVE_MILESTONE_FUND_REQUEST:
      return { ...state, approveMilestoneFundInProgress: true, approveMilestoneFundError: null };
    case APPROVE_MILESTONE_FUND_SUCCESS:
      return { ...state, approveMilestoneFundInProgress: false, approveMilestoneFundError: null };
    case APPROVE_MILESTONE_FUND_ERROR:
      return {
        ...state,
        approveMilestoneFundInProgress: false,
        approveMilestoneFundError: payload,
      };
    case UPDATE_TRANSACTION_METADATA_REQUESET:
      return { ...state, updateMetadataInProgress: true, updateMetadataError: null };
    case UPDATE_TRANSACTION_METADATA_SUCCESS:
      return { ...state, updateMetadataInProgress: false, updateMetadataError: null };
    case UPDATE_TRANSACTION_METADATA_ERROR:
      return {
        ...state,
        updateMetadataInProgress: false,
        updateMetadataError: payload,
      };
    case FETCH_JOB_LISTING_REQUEST:
      return { ...state, fetchJobListingInProgress: true, fetchJobListingError: null };
    case FETCH_JOB_LISTING_SUCCESS:
      return {
        ...state,
        fetchJobListingInProgress: false,
        jobListing: payload,
        fetchJobListingError: null,
      };
    case FETCH_JOB_LISTING_ERROR:
      return {
        ...state,
        fetchJobListingInProgress: false,
        fetchJobListingError: payload,
      };
    case UPDATE_CONTRACT_LISTING_REQUEST:
      return { ...state, updateContractListingInProgress: true, updateContractListingError: null };
    case UPDATE_CONTRACT_LISTING_SUCCESS:
      return {
        ...state,
        updateContractListingInProgress: false,
        updateContractListingError: null,
      };
    case UPDATE_CONTRACT_LISTING_ERROR:
      return {
        ...state,
        updateContractListingInProgress: false,
        updateContractListingError: payload,
      };
    case CREATE_CONTRACT_LISTING_REQUEST:
      return { ...state, createContractListingInProgress: true, createContractListingError: null };
    case CREATE_CONTRACT_LISTING_SUCCESS:
      return {
        ...state,
        createContractListingInProgress: false,
        createContractListingError: null,
        contractListing: payload,
      };
    case CREATE_CONTRACT_LISTING_ERROR:
      return {
        ...state,
        createContractListingInProgress: false,
        createContractListingError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const transitionInProgress = state => {
  return state.TransactionPage.transitionInProgress;
};
export const updateMetadataSelector = state => {
  const { updateMetadataInProgress, updateMetadataError } = state.TransactionPage;
  return {
    updateMetadataInProgress,
    updateMetadataError,
  };
};

export const milestoneSelector = state => {
  const {
    updateMilestoneInProgress,
    updateMilestoneError,
    fundMilestoneInProgress,
    fundMilestoneError,
    milestoneSubmitInProgress,
    milestoneSubmitError,
    approveMilestoneFundInProgress,
    approveMilestoneFundError,
  } = state.TransactionPage;

  return {
    updateMilestoneInProgress,
    updateMilestoneError,
    fundMilestoneInProgress,
    fundMilestoneError,
    milestoneSubmitInProgress,
    milestoneSubmitError,
    approveMilestoneFundInProgress,
    approveMilestoneFundError,
  };
};

export const ownListingSelector = state => {
  const { ownListings } = state.TransactionPage;
  return { ownListings };
};
export const jobListingSelector = state => {
  const { fetchJobListingInProgress, jobListing, fetchJobListingError } = state.TransactionPage;
  return { fetchJobListingInProgress, jobListing, fetchJobListingError };
};
export const acceptContractSelector = state => {
  const { acceptContractInProgress } = state.TransactionPage;
  return { acceptContractInProgress };
};
export const sendAttachmentsSelector = state => {
  const { sendAttachmentsInProgress, sendAttachmentsError } = state.TransactionPage;
  return {
    sendAttachmentsInProgress,
    sendAttachmentsError,
  };
};
// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});
export const acceptContractRequest = () => ({ type: ACCEPT_CONTRACT_REQUEST });
export const acceptContractSuccess = () => ({ type: ACCEPT_CONTRACT_SUCCESS });
export const acceptContractError = error => ({
  type: ACCEPT_CONTRACT_ERROR,
  error: true,
  payload: error,
});
const fetchOwnListingsRequest = () => ({ type: FETCH_OWN_LISTINGS_REQUEST });
const fetchOwnListingsSuccess = response => ({
  type: FETCH_OWN_LISTINGS_SUCCESS,
  payload: response,
});
const fetchOwnListingsError = e => ({ type: FETCH_OWN_LISTINGS_ERROR, error: true, payload: e });

const fetchJobListingRequest = () => ({ type: FETCH_JOB_LISTING_REQUEST });
const fetchJobListingSuccess = response => ({
  type: FETCH_JOB_LISTING_SUCCESS,
  payload: response,
});
const fetchJobListingError = e => ({ type: FETCH_JOB_LISTING_ERROR, error: true, payload: e });

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const transitionRequest = transitionName => ({ type: TRANSITION_REQUEST, payload: transitionName });
const transitionSuccess = () => ({ type: TRANSITION_SUCCESS });
const transitionError = e => ({ type: TRANSITION_ERROR, error: true, payload: e });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendAttachmentsRequest = () => ({ type: SEND_ATTACHMENTS_REQUEST });
const sendAttachmentsSuccess = () => ({ type: SEND_ATTACHMENTS_SUCCESS });
const sendAttachmentsError = e => ({ type: SEND_ATTACHMENTS_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});
export const updateMilestoneRequest = () => ({ type: MILESTONE_UPDATE_REQUEST });
export const updateMilestoneSuccess = () => ({
  type: MILESTONE_UPDATE_SUCCESS,
});
export const updateMilestoneError = error => ({
  type: MILESTONE_UPDATE_ERROR,
  error: true,
  payload: error,
});
export const fundMilestoneRequest = () => ({ type: FUND_MILESTONE_REQUEST });
export const fundMilestoneSuccess = () => ({
  type: FUND_MILESTONE_SUCCESS,
});
export const fundMilestoneError = error => ({
  type: FUND_MILESTONE_ERROR,
  error: true,
  payload: error,
});
export const milestoneSubmitRequest = () => ({ type: MILESTONE_SUBMIT_REQUEST });
export const milestoneSubmitSuccess = () => ({
  type: MILESTONE_SUBMIT_SUCCESS,
});
export const milestoneSubmitError = error => ({
  type: MILESTONE_SUBMIT_ERROR,
  error: true,
  payload: error,
});

export const approveMilestoneFundRequest = () => ({ type: APPROVE_MILESTONE_FUND_REQUEST });
export const approveMilestoneFundSuccess = () => ({
  type: APPROVE_MILESTONE_FUND_SUCCESS,
});
export const approveMilestoneFundError = error => ({
  type: APPROVE_MILESTONE_FUND_ERROR,
  error: true,
  payload: error,
});
export const updateMetadataRequest = () => ({ type: UPDATE_TRANSACTION_METADATA_REQUESET });
export const updateMetadataSuccess = () => ({
  type: UPDATE_TRANSACTION_METADATA_SUCCESS,
});
export const updateMetadataError = error => ({
  type: UPDATE_TRANSACTION_METADATA_ERROR,
  error: true,
  payload: error,
});
export const updateContractListingRequest = () => ({ type: UPDATE_CONTRACT_LISTING_REQUEST });

export const updateContractListingSuccess = () => ({
  type: UPDATE_CONTRACT_LISTING_SUCCESS,
});
export const updateContractListingError = error => ({
  type: UPDATE_CONTRACT_LISTING_ERROR,
  error: true,
  payload: error,
});
export const createContractListingRequest = () => ({ type: CREATE_CONTRACT_LISTING_REQUEST });

export const createContractListingSuccess = response => ({
  type: CREATE_CONTRACT_LISTING_SUCCESS,
  payload: response,
});
export const createContractListingError = error => ({
  type: CREATE_CONTRACT_LISTING_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const nextBoundary = findNextBoundary(new Date(), 'hour', tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

// Helper to fetch correct image variants for different thunk calls
const getImageVariants = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  return {
    'fields.image': [
      // Profile images
      'variants.square-small',
      'variants.square-small2x',

      // Listing images:
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };
};

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

/**
 * Injects a transaction's included provider relationship as the included
 * listing's author relationship.
 * @param {*} txResponse A SDK response from transactions.show().
 * @returns a copy of the txResponse parameter with a listing.author
 * relationship added, if provider exists in the transaction's relationships.
 */
const injectAuthorRelationship = txResponse => {
  const {
    included,
    data: {
      relationships: { provider },
    },
  } = txResponse.data;

  // If provider has not been included, return the response with no changes.
  if (!provider?.data?.id) {
    return txResponse;
  }

  const includedListingIdx = included.findIndex(inc => inc.type === 'listing');

  // We will set the transaction's provider as the listing's author.
  // The full user resource we want to associate with the listing is
  // already available in the response.data.included array, so we only
  // need to add a relationship reference in the included listing resource.
  included[includedListingIdx] = {
    ...included[includedListingIdx],
    relationships: {
      ...included[includedListingIdx].relationships,
      author: {
        data: {
          id: provider?.data?.id,
          type: 'user',
        },
      },
    },
  };

  return {
    ...txResponse,
    data: {
      ...txResponse.data,
      included: [...included],
    },
  };
};

export const fetchTransaction = (id, txRole, config) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'listing.currentStock',
          'listing.images',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...getImageVariants(config.layout.listingImage),
      },
      { expand: true }
    )
    .then(response => {
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];
      const processName = resolveLatestProcessName(transaction.attributes.processName);

      try {
        const process = getProcess(processName);
        const isInquiry = process.getState(transaction) === process.states.INQUIRY;

        // Fetch time slots for transactions that are in inquired state
        const canFetchTimeslots =
          txRole === 'customer' && isBookingProcess(processName) && isInquiry;

        if (canFetchTimeslots) {
          fetchMonthlyTimeSlots(dispatch, listing);
        }
      } catch (error) {}

      // API does not allow fetching transaction.listing.author, so we will
      // set the relationship manually based on the transaction's provider.
      return injectAuthorRelationship(response);
    })
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(fetchTransactionSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

const delay = ms => new Promise(resolve => window.setTimeout(resolve, ms));
const refreshTx = (sdk, txId) => sdk.transactions.show({ id: txId }, { expand: true });
const refreshTransactionEntity = (sdk, txId, dispatch) => {
  delay(3000)
    .then(() => refreshTx(sdk, txId))
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      const lastTransition = response?.data?.data?.attributes?.lastTransition;
      // We'll make another attempt if mark-received-from-purchased from default-purchase process is still the latest.
      if (lastTransition === 'transition/mark-received-from-purchased') {
        return delay(8000)
          .then(() => refreshTx(sdk, txId))
          .then(response => {
            dispatch(addMarketplaceEntities(response));
          });
      }
    })
    .catch(e => {
      // refresh failed, but we don't act upon it.
    });
};

export const makeTransition = (transaction, transitionName, params) => async (
  dispatch,
  getState,
  sdk
) => {
  const txId = transaction?.id;
  const listing = transaction?.listing || {};
  // Prevent multiple transitions if one is already in progress
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }

  try {
    dispatch(transitionRequest(transitionName));

    const { currentUser } = getState()?.user || {};
    const currentUserId = currentUser?.id?.uuid;

    // Make the transaction transition call
    const response = await sdk.transactions.transition(
      { id: txId, transition: transitionName, params },
      { expand: true }
    );

    // Dispatch actions after a successful transition
    dispatch(addMarketplaceEntities(response));
    dispatch(transitionSuccess());
    dispatch(fetchCurrentUserNotifications());

    // Refresh the transaction entity with potential delayed updates
    refreshTransactionEntity(sdk, txId, dispatch);

    const transactionData = response.data.data;
    const transactionReadStatus = getTransactionReadStatus(transactionData);

    // Find the other user’s read status by filtering out the current user's ID
    const currentAuthorId = Object.keys(transactionReadStatus).find(
      userId => userId !== currentUserId
    );

    // Mark the transaction as read for the current user
    await dispatch(
      markTransactionAsReadHelper({
        transaction: transactionData,
        isRead: false,
        currentUserId,
        currentAuthorId,
      })
    );
    if (transitionName === TRANSITION_END_CONTRACT) {
      sdk.ownListings.close({
        id: listing?.id,
      });
    }
    return response;
  } catch (e) {
    // Handle the error by dispatching the transition error and logging
    dispatch(transitionError(storableError(e)));
    log.error(e, `${transitionName}-failed`, {
      txId,
      transition: transitionName,
    });

    throw e; // Rethrow the error to be handled further up the chain if needed
  }
};

const fetchMessages = (txId, page, config) => (dispatch, getState, sdk) => {
  const paging = { page, perPage: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...getImageVariants(config.layout.listingImage),
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1, config))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = (txId, config) => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage, config));
};
export const sendMessage = (txId, message, config) => async (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  try {
    const response = await sdk.messages.send({ transactionId: txId, content: message });
    const messageId = response.data.data.id;

    try {
      // Fetch the first page again to add the sent message to the page data
      // and to update any possible incoming messages.
      await dispatch(fetchMessages(txId, 1, config));
      dispatch(sendMessageSuccess());
      if (txId) {
        await updateMetadataThroughIsdk({
          id: txId,
          metadata: {
            updatedAt: moment().unix(),
          },
        });
      }
    } catch (error) {
      // Even if fetching messages fails, still consider the send successful
      dispatch(sendMessageSuccess());
    }

    return messageId;
  } catch (error) {
    dispatch(sendMessageError(storableError(error)));
    // Rethrow so the page can track whether the sending failed, and
    // keep the message in the form for a retry.
    throw error;
  }
};

// If other party has already sent a review, we need to make transition to
// transitions.REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (txId, transition, params, dispatch, sdk, config) => {
  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition(
      { id: txId, transition, params },
      { expand: true, include, ...getImageVariants(config.layout.listingImage) }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// transitions.REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (txId, transition, params, dispatch, sdk, config) => {
  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition(
      { id: txId, transition, params },
      { expand: true, include, ...getImageVariants(config.layout.listingImage) }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(id, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (tx, transitionOptionsInfo, params, config) => (
  dispatch,
  getState,
  sdk
) => {
  const { reviewAsFirst, reviewAsSecond, hasOtherPartyReviewedFirst } = transitionOptionsInfo;
  dispatch(sendReviewRequest());

  return hasOtherPartyReviewedFirst
    ? sendReviewAsSecond(tx?.id, reviewAsSecond, params, dispatch, sdk, config)
    : sendReviewAsFirst(tx?.id, reviewAsFirst, params, dispatch, sdk, config);
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};
export const updateMilestoneThroughIsdk = params => async (dispatch, getState, sdk) => {
  dispatch(updateMilestoneRequest());
  const { txRole, config, ...rest } = params;
  const txId = new UUID(params?.id);
  try {
    const response = await transitionTransaction(rest);
    dispatch(updateMilestoneSuccess());
    dispatch(fetchTransaction(txId, txRole, config));
    return response;
  } catch (error) {
    dispatch(updateMilestoneError(storableError(error)));
  }
};
export const handleMilestoneFunding = params => async (dispatch, getState, sdk) => {
  dispatch(fundMilestoneRequest());
  const { txRole, config, ...rest } = params;
  const txId = new UUID(params?.id);
  try {
    await fundMilestone(rest);
    dispatch(fundMilestoneSuccess());
    dispatch(fetchTransaction(txId, txRole, config));
  } catch (error) {
    dispatch(fundMilestoneError(storableError(error)));
  }
};
export const submitMilestoneRequest = params => async (dispatch, getState, sdk) => {
  dispatch(milestoneSubmitRequest());
  const { txRole, config, message, currentUserId, transaction, currentAuthorId, ...rest } = params;
  const transactionId = params?.id?.uuid;

  try {
    // Perform the transaction transition
    await transitionTransaction(rest);

    // Send the message after the transaction transition succeeds
    await sdk.messages.send({
      transactionId: transactionId,
      content: message,
    });

    await dispatch(
      markTransactionAsReadHelper({
        transaction,
        isRead: false,
        currentUserId,
        currentAuthorId,
      })
    );
    // Fetch the updated transaction and messages after the message is sent
    await Promise.all([
      dispatch(fetchTransaction(params?.id, txRole, config)),
      dispatch(fetchMessages(transactionId, 1, config)),
    ]);

    dispatch(milestoneSubmitSuccess());
  } catch (error) {
    dispatch(milestoneSubmitError(storableError(error)));
  }
};
export const approveAndFundMilestone = params => async (dispatch, getState, sdk) => {
  dispatch(approveMilestoneFundRequest());
  const { txRole, config, message, currentUserId, transaction, currentAuthorId, ...rest } = params;
  const transactionId = params?.id?.uuid;
  const currentDate = moment().format('M/D/YYYY');

  try {
    // Perform the transaction transition
    await approveMilestoneFund(rest);
    // Send the message after the transaction transition succeeds
    await sdk.messages.send({
      transactionId: transactionId,
      content: `${message ||
        'The milestone has been reviewed and completed successfully.'} ${currentDate}`,
    });

    await dispatch(
      markTransactionAsReadHelper({
        transaction,
        isRead: false,
        currentUserId,
        currentAuthorId,
      })
    );
    // Fetch the updated transaction and messages after the message is sent
    await Promise.all([
      dispatch(fetchTransaction(params?.id, txRole, config)),
      dispatch(fetchMessages(transactionId, 1, config)),
    ]);

    dispatch(approveMilestoneFundSuccess());
  } catch (error) {
    dispatch(approveMilestoneFundError(storableError(error)));
  }
};
export const updateTransactionMetadata = params => async (dispatch, getState, sdk) => {
  dispatch(updateMetadataRequest());
  const { transactionRole, hasWeeklyTimesheetTransition, config, message, ...rest } = params;
  const transactionId = params?.id?.uuid;

  try {
    // Perform the transaction transition
    if (hasWeeklyTimesheetTransition) {
      await updateMetadataThroughIsdk(rest);
    } else {
      const transactionParams = {
        id: transactionId,
        transition: transitions.INITIATE_WEEKLY_TIMESHEEET,
        params: {
          metadata: {
            ...params.metadata,
          },
        },
      };
      await privilegedTransition(transactionParams);
    }
    // Fetch the updated transaction and messages after the message is sent
    dispatch(fetchTransaction(params?.id, transactionRole, config));
    dispatch(updateMetadataSuccess());
  } catch (error) {
    dispatch(updateMetadataError(storableError(error)));
  }
};
export const sendAttachments = params => async (dispatch, getState, sdk) => {
  dispatch(sendAttachmentsRequest());

  const { attachments = [], transactionRole, config, transactionId, existingAttachments = [] } =
    params || {};

  try {
    // Send each attachment message
    await Promise.all(
      attachments.map(async attachment => {
        const { data: { data = {} } = {} } = await sdk.messages.send({
          transactionId,
          content: attachment.key,
        });
        return data;
      })
    );

    // Update the metadata with the new attachments
    const updatedAttachments = [...existingAttachments, ...attachments];
    const attachmentMetadata = {
      id: transactionId,
      metadata: {
        attachments: updatedAttachments,
      },
    };
    await updateMetadataThroughIsdk(attachmentMetadata);

    // Fetch the updated transaction and messages after sending attachments
    await Promise.all([
      dispatch(fetchMessages(transactionId, 1, config)),
      dispatch(fetchTransaction(transactionId, transactionRole, config)),
    ]);

    dispatch(sendAttachmentsSuccess());
  } catch (error) {
    dispatch(sendAttachmentsError(storableError(error)));
  }
};
export const initiateAcceptContract = params => async (dispatch, getState, sdk) => {
  dispatch(acceptContractRequest());
  const { transaction, currentUserId, customerId, providerId } = params || {};
  const { protectedData = {} } = transaction?.attributes || {};
  const { budgetType, jobId } = protectedData?.contractDetails || {};
  const processName = getProcessName(transaction);
  try {
    const bodyParams = {
      transition: TRANSITION_ACCEPT_CONTRACT_AFTER_HIRING, //Need to refactor
      processAlias: budgetType === Budget.FIXED ? DEFAULT_INQUIRY_PROCESS : DEFAULT_HOURLY_PROCESS,
      params: {
        listingId: new UUID(jobId),
        protectedData: {
          ...protectedData,
          parentTransctionProcessName: processName,
          parentTransactionId: transaction?.id?.uuid,
        },
      },
    };
    const { data: { data = [] } = {} } = await sdk.transactions.initiate(bodyParams);
    dispatch(acceptContractSuccess());
    if (data?.id) {
      await sdk.transactions.transition({
        id: transaction?.id,
        transition: TRANSITION_ACCEPT_CONTRACT,
        params: {
          protectedData: {
            childTransactionId: data?.id?.uuid,
          },
        },
      });

      await dispatch(
        markTransactionAsReadHelper({
          transaction: data,
          isRead: false,
          currentUserId,
          currentAuthorId: customerId,
        })
      );
    }
    return data;
  } catch (error) {
    dispatch(acceptContractError(storableError(error)));
  }
};
export const initiateFetchOwnListings = () => async (dispatch, getState, sdk) => {
  dispatch(fetchOwnListingsRequest());
  try {
    const { data: { data: listings = [] } = {} } = await sdk.ownListings.query({});

    // Filter listings to exclude those with projectUnderContract set to true
    const filteredListings = listings.filter(listing => {
      const projectUnderContract =
        listing?.attributes?.publicData?.contractDetails?.projectUnderContract;
      return !projectUnderContract; // Keep the listing if projectUnderContract is not true
    });
    dispatch(fetchOwnListingsSuccess(filteredListings));
    return filteredListings;
  } catch (error) {
    dispatch(fetchOwnListingsError(storableError(error)));
  }
};

export const fetchJobListing = jobId => async (dispatch, getState, sdk) => {
  dispatch(fetchJobListingRequest());
  try {
    const { data: { data: listing = {} } = {} } = await sdk.listings.show({ id: jobId });

    dispatch(fetchJobListingSuccess(listing));
  } catch (error) {
    dispatch(fetchJobListingError(storableError(error)));
  }
};
export const createContractListing = params => async (dispatch, getState, sdk) => {
  dispatch(createContractListingRequest());
  try {
    const response = await createListingUsingIsdk(params);
    if (response.statusCode === StatusCode.CREATED) {
      dispatch(createContractListingSuccess(response.data));
      return response?.data;
    }
  } catch (error) {
    dispatch(createContractListingError(storableError(error)));
  }
};
export const updateContractListing = params => async (dispatch, getState, sdk) => {
  dispatch(updateContractListingRequest());
  try {
    const { data: { data: listing = {} } = {} } = await sdk.ownListings.update(params);
    dispatch(updateContractListingSuccess());
    return listing;
  } catch (error) {
    dispatch(updateContractListingError(storableError(error)));
  }
};
// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = (params, search, config) => async (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  try {
    const [transactionResponse] = await Promise.all([
      dispatch(fetchTransaction(txId, txRole, config)),
      dispatch(initiateFetchOwnListings()),
      dispatch(fetchMessages(txId, 1, config)),
      dispatch(fetchNextTransitions(txId)),
      dispatch(stripeCustomer()),
    ]);

    const currentTransaction = transactionResponse?.data?.data;
    const { currentUser } = getState().user || {};
    const currentUserId = currentUser?.id?.uuid;
    const transactionReadStatus = getTransactionReadStatus(currentTransaction);
    const isOwnTransaction = transactionReadStatus?.markedBy === currentUserId || false;
    const ownReadTransactionStatus = transactionReadStatus[(currentUser?.id?.uuid)] || null;
    const isTransactionRead = ownReadTransactionStatus?.isRead || false;
    const hasIsReadProperty = ownReadTransactionStatus?.isRead !== undefined;
    // You now have access to the transaction response here
    if (currentTransaction?.id) {
      const contractDetails = getContractDetails(currentTransaction);
      const jobId = contractDetails?.jobId;
      if (jobId) {
        dispatch(fetchJobListing(jobId));
      }
    }
    if (currentTransaction?.id && hasIsReadProperty && !isTransactionRead) {
      await dispatch(
        markTransactionAsReadHelper({
          transaction: currentTransaction,
          isRead: isTransactionRead ? false : true,
          currentUserId: currentUser?.id?.uuid,
          currentAuthorId: currentUser?.id?.uuid,
        })
      );

      dispatch(fetchCurrentUserNotifications());
    }
    // Continue with other logic if necessary...
  } catch (error) {
    console.error('Error loading data:', error);
    // Handle the error as needed
  }
};
